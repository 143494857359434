import { Outlet } from "react-router-dom";

const SharedOutLet = () => {
  return (
    <div className="relative">
      <Outlet />
    </div>
  );
};

export default SharedOutLet;
