import { useRef } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const RQProvider = ({ children }) => {
  const queryRef = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryRef.current}>
      {children}
    </QueryClientProvider>
  );
};

export default RQProvider;
