import React from "react";
import { HashLoader } from "react-spinners";

const PageLoad = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="loader flex">
        <HashLoader size={100} color="#36d7b7" />
      </div>
    </div>
  );
};

export default PageLoad;
