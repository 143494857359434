import { Suspense, lazy } from "react";
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import PageLoad from "../shared/loading";
import SharedOutLet from "../shared/sharedOutlet";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<PageLoad />}>
    <Component {...props} />
  </Suspense>
);

// public page

const CaseData = Loadable(
  lazy(() => import("../components/dashboard/casedata"))
);

//manager Deck
const MangerShared = Loadable(
  lazy(() => import("../components/managerDash/shared"))
);
const ManagerDeckHome = Loadable(
  lazy(() => import("../components/managerDash/homepage"))
);

const SopsAnalytics = Loadable(
  lazy(() =>
    import("../components/managerDash/SopsAnalyticspage/sopsAnalytics")
  )
);

const Managerdeck = Loadable(
  lazy(() =>
    import("../components/managerDash/SopsAnalyticspage/sopsAnalytics")
  )
);

//SopsAnalytics
const SopsAnalyticsUpload = Loadable(
  lazy(() =>
    import("../components/managerDash/SopsAnalyticspage/sopsAnalyticsUpload")
  )
);

//rnrPages
const RnrHomePage = Loadable(lazy(() => import("../components/rnr/homepage")));

// Hr Portal
const HrIndexShared = Loadable(
  lazy(() => import("../components/hrDashboard/hrShared"))
);
const HrHomePage = Loadable(
  lazy(() => import("../components/hrDashboard/homepage"))
);

//no path
const NotFound = Loadable(lazy(() => import("../components/notfound")));
async function notLog() {
  const isAuth = true;
  if (isAuth === false) {
    console.log("i ran");
    throw redirect("/");
  }
  return null;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <SharedOutLet />,
    children: [
      {
        path: "/dashboard",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <CaseData />,
          },
          {
            path: "auxrecord",
            element: <CaseData />,
          },
        ],
      },
      {
        path: "/managerdeck",
        element: <MangerShared />,
        children: [
          {
            index: true,
            element: <ManagerDeckHome />,
          },
          {
            path: "sopsanalyticsupload",
            element: <SopsAnalyticsUpload />,
          },
          {
            path: "sopsanalytics",
            element: <SopsAnalytics />,
          },
        ],
      },
      {
        path: "/rnr",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <RnrHomePage />,
          },
        ],
      },
      {
        path: "/hellohr",
        element: <HrIndexShared />,
        loader: notLog,
        children: [
          {
            index: true,
            element: <HrHomePage />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

const routes = router;
export default routes;
