import Axios from "axios";

const token = localStorage.getItem("token");

function contractHeader() {
  let header = {};

  if (!token) {
    header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  } else {
    header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
  }
  return header;
}

let url = "";
if (window.location.href.includes("localhost")) {
  url = "http://localhost:7001/api/v1";
} else {
  url = "https://raven-api.tools.amazon.dev/dev/api/v1";
}

const axios = Axios.create({
  baseURL: url,
  withCredentials: true,
  credentials: "include",
  headers: contractHeader(),
});

export default axios;
