const today = new Date();

export const initialState = {
  isAuth: null,
  midWayToken: null,
  authToken: null,
  userId: null,
  token: null,
  role: null,
  date: today,
  adminModal: false,
  actionUserId: null,
  currentUserId: null,
};

export const actionTypes = {
  SET_IsAuth: "SET_IsAuth",
  SET_MidWayToken: "SET_MidwayToken",
  SET_authToken: "SET_authToken",
  SET_USER_ID: "SET_USER_ID",
  SET_TOKEN: "SET_TOKEN",
  SET_ROLE: "SET_ROLE",
  SET_LOGIN: "SET_LOGIN",
  SET_LOGOUT: "SET_LOGOUT",
  SET_DATE: "SET_DATE",
  SET_AdminModal: "SET_SET_AdminModal",
  SET_ActionUserId: "SET_ActionUserId",
  SET_CurrentId: "SET_CurrentId",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_IsAuth:
      return { ...state, isAuth: action.isAuth };
    case actionTypes.SET_MidWayToken: {
      return { ...state, midWayToken: action.midWayToken };
    }
    case actionTypes.SET_authToken: {
      return { ...state, authToken: action.authToken };
    }
    case actionTypes.SET_USER_ID:
      return { ...state, userId: action.userId };
    case actionTypes.SET_TOKEN:
      return { ...state, token: action.token };
    case actionTypes.SET_ROLE:
      return { ...state, role: action.role };
    case actionTypes.SET_DATE:
      return { ...state, date: action.date };
    case actionTypes.SET_AdminModal:
      return { ...state, adminModal: action.value };
    case actionTypes.SET_ActionUserId:
      return { ...state, actionUserId: action.id };
    case actionTypes.SET_CurrentId:
      return { ...state, currentUserId: action.id };

    default:
      return state;
  }
};

export default reducer;
