import jwtDecode from "jwt-decode";
import { useCookies } from "react-cookie";
import useHttpClient from "./useHttpClient";
import useZustandGlobalStore from "../GlobalStore/ZustandGlobal";

const useAuthHook = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const { sendRequest, loading, error, cRes } = useHttpClient();
  const { setterFunction } = useZustandGlobalStore();
  function nonce() {
    let a = "";
    const b = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let c = 0; 64 > c; c += 1)
      a += b.charAt(Math.floor(Math.random() * b.length));
    return a;
  }
  async function setAuthGlobalValue(globalValue) {
    const { authToken, midwayToken } = globalValue;
    const decodeAuthToken = jwtDecode(authToken);
    const { userId, role, _id } = decodeAuthToken.UserInfo;
    const globalValueToSend = {
      role,
      userId,
      id: _id,
      authToken,
      midwayToken,
      isAuth: true,
    };
    await setterFunction(globalValueToSend);
  }

  function midWayUrlContruct() {
    const nounceValue = nonce();
    const currentClientPath = encodeURIComponent(window.location.href);
    let url = `https://midway-auth.amazon.com/SSO?client_id=raven.tools.amazon.dev&redirect_uri=${currentClientPath}&response_type=id_token&scope=openid&nonce=${nounceValue}&`;
    return url;
  }

  function callMidway() {
    return new Promise(async (res, rej) => {
      let url = midWayUrlContruct();
      await fetch(url, { credentials: "include" })
        .then(async (tokenRes) => {
          if (tokenRes.ok) {
            const tokenRetrieve = await tokenRes.text();
            console.log(tokenRetrieve);
            res(tokenRetrieve);
          } else {
            rej(onErrorOpenAuth(false));
          }
        })
        .catch((err) => {
          if (err) {
            console.log("object");
            // window.location.href = url;
            onErrorOpenAuth(false);
          }
        });
    });
  }

  function tokenProcess() {
    return new Promise((res, rej) => {
      const { ravenToolAuth } = cookies;
      console.log(ravenToolAuth);
      //   let ravenToolAuth = `eyJ0eXAiOiJKV1MiLCJhbGciOiJSUzI1NiIsImtpZCI6IjEzNDAyOTU4In0.eyJpc3MiOiJodHRwczovL21pZHdheS1hdXRoLmFtYXpvbi5jb20iLCJzdWIiOiJrdW5pa2hpZiIsImF1ZCI6InJhdmVuLnRvb2xzLmFtYXpvbi5kZXYiLCJleHAiOjE2OTA5NzE3OTEsImlhdCI6MTY5MDk3MDg5MSwiYXV0aF90aW1lIjoxNjkwOTAzNTMyLCJub25jZSI6Im55N3RFVHJ3NXpUczVQdGMzTE16bzBwTzR4ZFlRYnpoWUNTdmZTUm5xWWRiMlRrakhub1dCSFM1dUNFN09STGgiLCJhbXIiOiJbXCJwaW5cIiwgXCJ5dWJpb3RwXCIsIFwicGVzXCJdIiwidHJ1c3Rfc2NvcmUiOjEsInJlcV9pZCI6IjdmN2RhODFhLTYxZTMtNDljMC1hYjgzLWE1MDlkMmJlNGM1YyIsIm1pZHdheV90cnVzdF9zY29yZSI6bnVsbCwiYXBlc19yZXN1bHRzIjp7IlRaX0NPUlBfTEFQVE9QIjp7InBhc3MiOnRydWUsInN0YXR1cyI6IlNVQ0NFU1MiLCJmYWlsdXJlQmxvY2tzQWNjZXNzIjpmYWxzZSwiY2xhaW1zIjpbXSwic3RlcHVwQ2xhaW1zIjpbXX0sIlRaX0NPUlAiOnsicGFzcyI6dHJ1ZSwic3RhdHVzIjoiU1VDQ0VTUyIsImZhaWx1cmVCbG9ja3NBY2Nlc3MiOnRydWUsImNsYWltcyI6W10sInN0ZXB1cENsYWltcyI6W119fSwiaWFsIjowLCJhYWwiOjAsImp0aSI6InBFdzdmODQxN1lYWlJ1ZmJJdHQ1TFE9PSIsInBvc3R1cmVfY2hlY2siOjEsIm13aSI6MH0.XUdf7dh71qj77koTJYc_iW999jRe_gJ41CFWQjXunwO5OXxEp-r9hoDuLuq0xGU5mBeHrgiEdjUxFWwP3FX2lMvwZA6IMy2gedV_8lzpYAp05cTIwlN2XA3TcmnD_tzArsawePMZhruKuwKQILm-XfVg5NCaoN8F1OuxR3-vTLlLL3qYx5-qZ5WIbsGd0r7Bggg8Y3JnSN1O2fAGY1spGzGIgPXS3oDWagy7wPFFSPLEVqh7rqzJgiOIsKwmq-uLPOQFEEdhHuPuBSZJ8YuL0FAsfocJWMBUaDf-azBJ7cnr9uCGrP7tyYsleblEALdY5c3J_mSvCEOLaLS4-_tRbg`;
      if (ravenToolAuth) {
        console.log("token Found");
        const { exp } = jwtDecode(ravenToolAuth);
        isTokenExpire(exp).then((isExpire) => {
          if (isExpire) {
            callMidway()
              .then((token) => {
                removeCookie("ravenToolAuth", { path: "/" });
                setCookie("ravenToolAuth", token);
                res({ isExpire, midwayToken: token });
              })
              .catch((err) => {
                console.log(err);
                onErrorOpenAuth(false);
              });
          } else {
            console.log("token is Valid");
            res(res({ isExpire, midwayToken: ravenToolAuth }));
          }
        });
      } else {
        console.log("running Else token Test");
        callMidway()
          .then((token) => {
            removeCookie("ravenToolAuth", { path: "/" });
            setCookie("ravenToolAuth", token);
            res({ isExpire: true, midwayToken: token });
          })
          .catch((err) => {
            console.log("object");
            onErrorOpenAuth(false);
          });
      }
    });
  }

  const onErrorOpenAuth = async (isAuth) => {
    if (!isAuth) {
      const globalValueToSend = {
        role: null,
        userId: null,
        id: null,
        authToken: null,
        midwayToken: null,
        isAuth: false,
      };
      await setterFunction(globalValueToSend);
      // window.location.href = midWayUrlContruct();
    }
  };

  function loginOnValidToken(midWayRep) {
    return new Promise(async (res, rej) => {
      const { isExpire, midwayToken } = midWayRep;
      const { sub: userId } = jwtDecode(midwayToken);
      if (isExpire) {
        const { data, status } = await sendRequest("/auth", "POST", {
          userId,
        });
        if (data && status === 201) {
          const { accessToken } = data;
          removeCookie("authToken", { path: "/" });
          setCookie("authToken", accessToken);
          const globalValue = {
            authToken: accessToken,
            midwayToken: midwayToken,
            userId,
          };
          res(setAuthGlobalValue(globalValue));
        } else {
          alert("somthing is not right");
        }
      } else {
        const { authToken } = cookies;
        const globalValue = {
          authToken: authToken,
          midwayToken: midwayToken,
          userId,
        };
        res(setAuthGlobalValue(globalValue));
      }
    });
  }

  function runValidator() {
    return new Promise((res, rej) => {
      tokenProcess()
        .then((token) => {
          res(loginOnValidToken(token));
        })
        .catch((err) => {
          rej(onErrorOpenAuth(false));
        });
    });
  }

  function isTokenExpire(exp) {
    return new Promise((res, rej) => {
      let statement = new Date(exp) * 1000 <= new Date();
      console.log("Token " + statement);
      res(statement);
    });
  }
  return { runValidator };
};

export default useAuthHook;
