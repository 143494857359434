import "./App.css";
import { useRoutes, RouterProvider } from "react-router-dom";
import routes from "./routes";
import useAuthHook from "./hooks/useAuthHook";
import { useEffect } from "react";

function App() {
  const { runValidator } = useAuthHook();

  useEffect(() => {
    console.log("app token test");
    runValidator().catch((err) => console.log(err));
  }, []);
  // const content = useRoutes(routes);

  return <RouterProvider router={routes} />;
}

export default App;
