import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./index.css";
import "./reset.css";
import App from "./App";
import RQProvider from "./global/rqContexProvider";
import reducer, { initialState } from "./Store/reducer";
import { StateProvider } from "./Store/StateProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <RQProvider>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
        <ReactQueryDevtools />
      </StateProvider>
    </RQProvider>
  </React.StrictMode>
);
