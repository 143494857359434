import { create } from "zustand";

const useZustandGlobalStore = create((set) => ({
  userId: "",
  isAuth: false,
  midwayToken: null,
  role: null,
  authToken: null,
  currentUserId: null,
  setterFunction: (globalValue) => {
    const { role, userId, id, authToken, midwayToken, isAuth } = globalValue;
    set((state) => ({
      midwayToken,
      isAuth,
      userId,
      role,
      currentUserId: id,
      authToken,
    }));
  },
}));

export default useZustandGlobalStore;
